import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

import background from '../images/cedule-bg.svg'

const Badge = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        padding: 3,
        backgroundImage: `url(${background})`,
        backgroundColor: 'darkGray',
        backgroundSize: '80%',
        borderRadius: '1rem',
        boxShadow: '0 4px 32px rgba(0, 0, 0, 0.4)',
        transition: 'transform 200ms ease-in-out',
        transform: 'rotate(-2deg)',
        maxWidth: ['100%', '80%'],
        mx: 'auto',
        '&:hover': {
          transform: 'rotate(3deg)',
        },
      }}
      {...props}
    >
      <Box
        sx={{
          padding: 4,
          border: '3px solid rgba(255, 255, 255, 0.8)',
          borderRadius: '0.85rem',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Badge
