import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

const OpeningHours = ({ openingHours }) => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column'],
        alignItems: 'center',
        justifyContent: ['center', 'space-between'],
        textAlign: 'center',
      }}
    >
      <Box>
        <Heading
          as="h2"
          variant="sectionTitle"
          sx={{ mb: 3, color: 'gold' }}
        >
          <FormattedMessage
            id="offer.openingHours"
            defaultMessage="Otevírací doba"
          />
        </Heading>
      </Box>

      <Box>
        <Text
          sx={{ m: 0, color: 'gold', fontSize: 10, lineHeight: '1.25' }}
          dangerouslySetInnerHTML={{ __html: openingHours }}
        />
      </Box>
    </Flex>
  )
}

OpeningHours.propTypes = {
  openingHours: PropTypes.string.isRequired,
}

export default OpeningHours
