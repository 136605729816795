import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Text, Link } from 'rebass/styled-components'

const ContactPeople = ({ people }) => {
  return (
    <>
      {people && (
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {people.map((kontaktniOsoba) => {
            const { osoba } = kontaktniOsoba
            const { jmeno, pozice, telefon, e_mail: email, fotografie } = osoba

            return (
              <Flex
                sx={{
                  mb: [5, 5],
                  width: ['100%', '50%', '32%'],
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {fotografie && (
                  <Box
                    sx={{
                      mb: 3,
                      borderRadius: '150px',
                      boxShadow: '0 4px 32px rgba(0, 0, 0, 0.3)',
                      overflow: 'hidden',
                    }}
                  >
                    <Img
                      style={{ width: '18.75rem', height: '18.75rem' }}
                      fluid={fotografie.localFile.childImageSharp.fluid}
                      alt={jmeno}
                    />
                  </Box>
                )}

                <Text
                  sx={{
                    mb: 2,
                    color: 'gold',
                    opacity: 0.7,
                  }}
                >
                  {pozice}
                </Text>

                <Heading
                  sx={{
                    mb: 3,
                    color: 'white',
                    fontSize: 9,
                    fontWeight: 'bold',
                  }}
                  as="h3"
                >
                  {jmeno}
                </Heading>

                <Flex
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {telefon && (
                    <Text mb={3}>
                      <Link href={`tel:${telefon.replace(/ /gi, '')}`}>
                        {telefon}
                      </Link>
                    </Text>
                  )}
                  {email && (
                    <Text>
                      <Link href={`mailto:${email.replace(/ /gi, '')}`}>
                        {email}
                      </Link>
                    </Text>
                  )}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      )}
    </>
  )
}

ContactPeople.propTypes = {
  people: PropTypes.array.isRequired,
}

export default ContactPeople
