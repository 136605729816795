import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'
import { rgba } from 'polished'

const GlassEffect = ({ children }) => (
  <Box
    sx={{
      padding: 2,
      backgroundColor: rgba('#fff', 0.1),
      borderRadius: '6px',
      boxShadow: '0 12px 32px rgba(0, 0, 0, 0.3)',
      '& > div': {
        borderRadius: '4px',
        overflow: 'hidden',
      }
    }}
  >
    {children}
  </Box>
)

GlassEffect.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlassEffect
