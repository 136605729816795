import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Heading, Text, Image } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  SectionWithObjectsGold,
  SectionWithObjects,
} from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import OpeningHours from '../components/opening-hours'
import ContactPeople from '../components/contact-people'
import { PinBrewery } from '../components/pins'
import Map from '../components/map'
import GlassEffect from '../components/glass-effect'
import Badge from '../components/badge'
import StyledMapWrapper from '../components/styled-map-wrapper'
import getTranslations from '../utils/getTranslations'
import pinBrewery from '../images/map-pin-brewery.svg'

const ContactPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, heroBackgroundImage, breweryImage } = data
  const {
    title,
    // content,
    acf,
    polylang_translations: translations,
  } = page
  const {
    oteviraci_doba: openingHours,
    kontaktni_osoby: kontaktniOsoby,
    paticka_kontaktu: patickaKontaktu,
    adresa,
    popis_v_hero: popisVHero,
  } = acf

  const mapCenter = { lat: 50.0870946, lng: 17.7025084 }
  const mapZoom = 17

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={popisVHero || null}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 70%"
      >
        <Badge>
          <OpeningHours openingHours={openingHours} />
        </Badge>
      </Hero>

      <SectionWithObjects>
        <Container>
          <ContactPeople people={kontaktniOsoby} />
        </Container>
      </SectionWithObjects>

      <SectionWithObjectsGold>
        <Container>
          <Flex flexDirection={['column']}>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                width: ['100%'],
                mb: 4,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Heading
                  variant="sectionTitle"
                  sx={{ mb: 0, color: 'darkGray' }}
                >
                  <FormattedMessage
                    id="contact.address"
                    defaultMessage="Adresa"
                  />
                </Heading>
              </Box>

              <Flex>
                <Image
                  sx={{ mr: 3 }}
                  src={pinBrewery}
                  alt=""
                  aria-hidden="true"
                />
                <Text
                  sx={{
                    color: 'darkGray',
                    fontSize: 8,
                    lineHeight: 1.25,
                    textAlign: ['center', 'left'],
                  }}
                  dangerouslySetInnerHTML={{ __html: adresa }}
                />
              </Flex>
            </Flex>

            <Box sx={{ width: '100%' }}>
              <Map
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCFafjdD1i1-UT6uN4z7WVt4lSw4Aotbjk&amp;v=3.exp&amp;libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<StyledMapWrapper />}
                mapElement={<div style={{ height: '100%' }} />}
                center={mapCenter}
                zoom={mapZoom}
                markers={[
                  {
                    key: 1,
                    icon: PinBrewery,
                    lat: Number(mapCenter.lat),
                    lng: Number(mapCenter.lng),
                  },
                ]}
              />
            </Box>
          </Flex>
        </Container>
      </SectionWithObjectsGold>

      <SectionWithObjects>
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: 'space-between',
            }}
          >
            <Text
              variant="body"
              sx={{
                flexBasis: ['100%', '33%'],
                order: [1, 0],
                '& ul, & p': {
                  mb: 3,
                },
              }}
              dangerouslySetInnerHTML={{ __html: patickaKontaktu }}
            />

            <Box
              sx={{
                flexBasis: ['100%', '60%'],
                order: [0, 1],
                mb: [3, 0],
              }}
            >
              <GlassEffect>
                <Img fluid={breweryImage.childImageSharp.fluid} alt="" />
              </GlassEffect>
            </Box>
          </Flex>
        </Container>
      </SectionWithObjects>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
        popis_v_hero
        oteviraci_doba
        paticka_kontaktu
        adresa
        kontaktni_osoby {
          osoba {
            jmeno
            pozice
            telefon
            e_mail
            fotografie {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "sidlo.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    breweryImage: file(relativePath: { eq: "sidlo.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
